<template>
    
    <!-- Модальне вікно на перегляд детальної інформації по пункту -->

    <modal :title="form.text ? form.text : $t('Untitled')" @close="$emit('close')">

        <template v-slot:body>
            
            <b-row>
                <b-col>
                    <div>
                        <b-tabs pills >
                            <b-tab >
                                <template #title>
                                    <span class="d-block d-sm-none"><i class="ri-information-line"></i></span>
                                    <span class="d-none d-sm-block">{{ $t('main') }}</span> 
                                </template>
                                <b-card>
                                    <b-row>

                                        <h5 class="fw-semibold text-uppercase mb-3 border-bottom pb-3">{{ form.text }}</h5>

                                        <b-col lg="12">

                                            <h5 class="fs-16 mb-3 text-danger fw-semibold">{{ $t('Testing') }}</h5>

                                            <!--  Чи була ця проблема раніше -->
                                            <div class="mb-3" >
                                                <div class="me-3 mb-1 text-muted fs-14" >
                                                    {{ $t('HastheUpdatebeenTested') }}
                                                </div>
                                                <div class="mt-2">
                                                    <p :class="`mb-0 fs-14 badge bg-${form.passedTesting ? 'success' : 'danger'}`">{{ form.passedTesting ? $t('y') : $t('n') }}</p>
                                                </div>
                                            </div>

                                            <!-- Ким проводилось тестування? -->
                                            <div class="mb-3" v-if="form.passedTesting == true && form.workers.length > 0" >
                                                <div class="me-3 mb-1 text-muted fs-14" >
                                                    {{ $t('WhoConductedtheTesting') }}
                                                </div>
                                                <div class="mt-3">
                                                    <div style="max-height: 215px;overflow: auto;"> 
                                                        <ul class="list-group">

                                                            <template v-for="(item, index) in form.workers" :key="index">
                                                                <li class="list-group-item">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="flex-grow-1">
                                                                            <div class="d-flex">
                                                                                <div class="flex-shrink-0 ms-2">
                                                                                    <h6 class="fs-14 mb-0">{{item.workerName}}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </template>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </b-col>

                                        <b-col lg="12" v-if="form.associatedWithTicket">

                                            <h5 class="fs-16 mb-3 mt-2 text-info fw-semibold">{{ $t('tickets') }}</h5>

                                            <!-- Чи є тікети пов'язані з пунктом -->
                                            <div class="mb-3" >
                                                <div class="me-3 mb-1 text-muted fs-14" >
                                                    {{ $t("upgradeItemAssociatedFollowingTickets") }}
                                                </div>
                                            </div>

                                            <!-- Тікети -->
                                            <div class="mb-3" v-if="form.associatedWithTicket == true" >
                                                
                                                <div class="mt-3">
                                                    <div style="max-height: 215px;overflow: auto;"> 
                                                        <template v-for="(item, index) in form.tickets" :key="index">
                                                            <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-1" >
                                                                <span class="badge bg-info fs-14">#{{ item.ticketId }}</span>

                                                                <div class="flex-grow-1 ms-3 copy" @click="open(item)" >
                                                                    <h5 class="mb-1">{{ item.ticketName }}</h5>
                                                                </div>

                                                                <div class="hstack gap-3 fs-16" >
                                                                    <!-- Відкрити тікет -->
                                                                    <button 
                                                                        @click="open(item)"
                                                                        class="copy btn btn-soft-secondary btn-icon"
                                                                    >
                                                                        <i class="ri-eye-line"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>

                                            </b-col>
                                    </b-row>
                                </b-card>

                                <b-card>

                                    <h5 class="fw-semibold text-uppercase mb-3 border-bottom pb-3">{{ $t('desc') }}</h5>

                                    <template v-if="form.description"> 
                                        <div class="ql-container">
                                            <div class="ql-editor" v-html="form.description">
                                            </div>
                                        </div> 
                                    </template>
                                    
                                    <template v-else>
                                        <div class="card-body text-center" >
                                            <h4 class="card-title fw-semibold">{{ $t('ThereisNoDescription') }} 🥺</h4>
                                            <p class="card-text text-muted">{{ $t('ThereisNoDescriptionDesc') }} 😉</p>
                                            <!-- <button class="btn btn-success">Додати</button> -->
                                        </div>
                                    </template>

                                </b-card>
                            </b-tab>

                            <b-tab >
                                <!-- Таб з файлами -->
                                <template #title>
                                    <span>
                                        {{ $t('Files') }} 
                                        <span v-if="form.files.length > 0" class="badge bg-warning fs-12">{{ form.files.length }}</span>
                                    </span> 
                                </template>

                                <div class="card" >
                                    <div class="card-body" >
                                        <div class="d-flex align-items-center mb-4" >
                                            <h5 class="card-title flex-grow-1 mb-0">{{ $t('Files') }} </h5>
                                            <!-- <div class="flex-shrink-0" v-if="perms[2000] || (perms[372] && this.objForm.createWorkerId == this.user.userid)">
                                                <input class="form-control d-none" type="file" id="formFile">
                                                <label for="formFile" class="btn btn-danger"><i class="ri-upload-2-fill me-1 align-bottom"></i> {{ $t('Download') }}</label>
                                            </div> -->
                                        </div>

                                        <template
                                            v-if="this.form.files.length > 0"
                                            v-for="item in form.files" :key="item"
                                        >
                                            <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-3" >
                                                <div class="avatar-sm copy" @click="viewFile(item)" >
                                                    <div :class="`avatar-title bg-light text-${this.mutateImg(item.type).color} rounded fs-24`">
                                                        <i :class="this.mutateImg(item.type).img"></i>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 ms-3 copy" @click="viewFile(item)">
                                                    <h6 class="mb-1"><a>{{ item.name }}</a></h6>
                                                    <small class="text-muted">{{ item.datetime }}</small>
                                                </div>
                                                <div class="hstack gap-3 fs-16" >

                                                    <!-- Завантажити -->
                                                    <button 
                                                        @click="this.downloadFile(item)" 
                                                        class="copy btn btn-soft-info btn-icon"
                                                    >
                                                        <i class="ri-download-2-line"></i>
                                                    </button>

                                                    <!-- Видалити файл -->
                                                    <button 
                                                        v-if="perms[2000] || (perms[372] && this.objForm.createWorkerId == this.user.userid)"
                                                        @click="showRemoveDialog(item)"
                                                        class="copy btn btn-soft-danger btn-icon "
                                                    >
                                                        <i class="ri-delete-bin-line"></i>
                                                    </button>

                                                </div>
                                            </div>

                                        </template>

                                    </div>

                                </div>

                            </b-tab>

                        </b-tabs>

                    </div>

                </b-col>

            </b-row>

        </template>

    </modal>

    <!-- Модальне вікно тікету -->
    <viewbox 
        v-if="modalShowview == true" 
        @close="modalShowview = false" 
        :obj="this.objTicket" 
    ></viewbox>

    <!-- Модальне вікно для підтвердження видалення файлу -->
    <modalremove 
        v-if="dialogShow"
        @close="dialogShow = false" 
        @remove="this.deleteFile(gFile)" 
    />

    <!-- Відображення зображення або відео без завантаження на пристрій -->
    <modallg v-if="showImg" @close="showImg = false">
        <template v-slot:body>
            <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                <img  :src="`${gFile.src}`" style="">
            </template>
            <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                <video controls>
                    <source :src="`${gFile.src}`" type="video/mp4">
                </video>
            </template>
        </template>
        <template v-slot:buttons>

            <!-- Завантажити файл -->
            <button 
                class="gdownload gbtn" 
                @click="this.downloadFile(gFile)" 
            >
                <i class="ri-download-cloud-2-fill"></i>
            </button>

            <!-- Видалити файл -->
            <button 
                v-if="perms[2000] || (perms[372] && this.objForm.createWorkerId == this.user.userid)"
                class="gdelete gbtn" 
                @click="showRemoveDialog(gFile)"
            >
                <i class="ri-delete-bin-fill"></i>
            </button>

            <!-- Відкрити попередній файл -->
            <button 
                :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" 
                @click="prevOpen(gFile)" 
                aria-label="Previous" 
                data-taborder="2"
            >
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg>
            </button>

            <!-- Відкрити наступний файл -->
            <button 
                :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" 
                @click="nextOpen(gFile)" 
                aria-label="Next" 
                data-taborder="1"
            >  
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg>
            </button>

        </template>
    </modallg>

</template>

<script>
import modal from '@/components/modal/modal-lg';
import modallg from '@/components/modal/img'
import viewbox from '@/views/newtickets/view/index.vue'
import modalremove from '@/components/modal/modalremove' // компонент діалогового вікна видалення
import { Tickets, Updates } from '@/API.js'
import { mutateImgFiles } from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Tickets();
let apiUpdates = new Updates();

export default {
    props: ['objCard', 'type', 'deadlineId', 'objForm'],
    components: {
        modal,
        viewbox,
        modalremove,
        modallg
    },
    data() {
        return {
            form: '',
            modalShowview: false, // відображення вікна тікету
            objTicket: '', //дані тікету
            dialogShow: false, // Прапорець для відображення діалогового вікна видалення файлу
            showImg: false, // Прапорець для відображення зображення
            gFile: { // Дані про обраний файл
                fileId: '',
                src: '',
                type: ''
            },
            ifPrevimg: false,
            ifNextimg: false,
            urlCaches: [] // для відео яке завантажували вже
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        open(e){
            // Відкрити вікно деталей тикета

            apiServe.getTicket(e.ticketId).then(result => {
                if(result.status == 'done'){
                    this.objTicket = result.data;
                    this.modalShowview = true;
                } else {
                    
                }
            })
        },
        showRemoveDialog(e){
            // Відображення діалогового вікна видалення файлу
            console.log("type", this.type);
            
            this.gFile.fileId = e.fileId
            this.dialogShow = true;
        },
        deleteFile(e){
            // Видалення файлу

            switch (this.type) {

                case 'opportunities': 

                    apiUpdates.deleteFileNewOpportunities(e.fileId).then(result => {

                        if(result.status == 'done') {
                            // this.form.opportunities = result.data;
                            this.$toast.success(this.$t("Removed"))
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення файлу до пункту нових можливостей'; 
                            var statusRequest = result.status;
                            var api = 'deleteFileNewOpportunities';
                            var fileRequest = 'src/views/changelog/view/viewItem/index.vue';
                            var params = this.showRemoveItem.item;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'improvements': 

                    apiUpdates.deleteFileImprovement(e.fileId).then(result => {

                        if(result.status == 'done') {
                            // this.form.improvements = result.data;
                            this.$toast.success(this.$t("Removed"))
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення файлу пункту покращення'; 
                            var statusRequest = result.status;
                            var api = 'deleteOpportunity';
                            var fileRequest = 'src/views/changelog/view/viewItem/index.vue';
                            var params = this.showRemoveItem.item;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'fixed': 

                    apiUpdates.deleteFileDeadlinesFixed(e.fileId).then(result => {

                        if(result.status == 'done') {
                            // this.form.fixed = result.data;
                            this.$toast.success(this.$t("Removed"))
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення файлу пункту виправлень'; 
                            var statusRequest = result.status;
                            var api = 'deleteFileDeadlinesFixed';
                            var fileRequest = 'src/views/changelog/view/viewItem/index.vue';
                            var params = this.showRemoveItem.item;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;

                default: 
                    console.log('log');
                break
            }

            this.dialogShow = false
            
        },
        viewFile(e) {
            // Перегляд файлу
            console.log("viewFile");
            

            if(e.type == 'image/jpeg' || e.type == 'image/png' || e.type == 'image/heic' || e.type == 'video/quicktime' || e.type == 'video/mp4'){
                // this.$emit('getFile', e)

                var id = e.fileId ? e.fileId : e;
                this.ifPrevimg = id == this.form.files[0].fileId ? false : true; // чи показувати стрілку на попередній док
                this.ifNextimg = id == this.form.files[this.form.files.length-1].fileId ? false : true; // чи показувати стрілку на наступний док
                
                if(storeS.blob != undefined){
                    var foundName = 'urlFrom' + this.deadlineId + "fileId" + id;
                    var urlCache = ''
                    var statusCache = this.urlCaches.some(function(e){ 
                        urlCache = e.url
                        return e.name == foundName;
                    });
                    if(statusCache){
                        console.log(urlCache);
                        // якщо відео є в локалстор
                        this.gFile.fileId = id;
                        this.gFile.src = urlCache;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name
                        this.showImg = true;
                    } else{
                        // якщо відео немає в локалстор - додаємо
                        this.switchGetFile(e, id);
                    }

                } else {
                   this.switchGetFile(e, id);
                }

            } else {
                // this.$toast.error(this.$t('fileNotSupported'));
                this.downloadFile(e)
            }
        },
        switchGetFile(e, id){
            switch (this.type) {

                case 'opportunities': 

                    apiUpdates.getFileOpportunities(e.fileId).then(result => {

                        var blob = new Blob([result.data])
                        const url = window.URL.createObjectURL(blob);
                        
                        let opUrl = {
                            name: 'urlFrom' + this.deadlineId + "fileId" + id,
                            url: url
                        }

                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);

                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name

                        this.showImg = true;
                        storeS.uploadPercentage = 0;

                    })

                break;
                case 'improvements': 

                    apiUpdates.getFileImprovement(e.fileId).then(result => {

                        var blob = new Blob([result.data])
                        const url = window.URL.createObjectURL(blob);
                        
                        let opUrl = {
                            name: 'urlFrom' + this.deadlineId + "fileId" + id,
                            url: url
                        }

                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);

                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name

                        this.showImg = true;
                        storeS.uploadPercentage = 0;

                    })

                break;
                case 'fixed': 

                    apiUpdates.getFileFixed(e.fileId).then(result => {

                        var blob = new Blob([result.data])
                        const url = window.URL.createObjectURL(blob);
                        
                        let opUrl = {
                            name: 'urlFrom' + this.deadlineId + "fileId" + id,
                            url: url
                        }

                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);

                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name

                        this.showImg = true;
                        storeS.uploadPercentage = 0;

                    })

                break;

                default: 
                    console.log('log');
                break
            }
        },
        downloadFile(e){
            // Завантажити файл

            switch (this.type) {

                case 'opportunities': 

                    apiUpdates.getFileOpportunities(e.fileId)
                        .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', e.name);
                            document.body.appendChild(link);
                            link.click();
                            storeS.uploadPercentage = 0;
                        })
                        .catch((error) => console.log(error));

                break;
                case 'improvements': 

                    apiUpdates.getFileImprovement(e.fileId)
                        .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', e.name);
                            document.body.appendChild(link);
                            link.click();
                            storeS.uploadPercentage = 0;
                        })
                        .catch((error) => console.log(error));

                break;
                case 'fixed': 

                    apiUpdates.getFileFixed(e.fileId)
                        .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', e.name);
                            document.body.appendChild(link);
                            link.click();
                            storeS.uploadPercentage = 0;
                        })
                        .catch((error) => console.log(error));

                break;

                default: 
                    console.log('log');
                break
            }

        },
        saveUrlFile(e){
            // Збереження файлу в локал
            storeS.blob = e;
        },
        prevOpen(e){
            // Попередній файл

            var current_id = e.fileId;
            var prev_id = ""; 

            for(var value in this.form.files){
                if(this.form.files[value].fileId == current_id){
                    prev_id = this.form.files[Number(value)-Number('1')];
                }
            }
            this.viewFile(prev_id);
        },
        nextOpen(e){
            // Наступний файл

            var current_id = e.fileId;
            var next_id = "";
            for(var value in this.form.files){
                if(this.form.files[value].fileId == current_id){
                    next_id = this.form.files[Number(value)+Number('1')];
                }
            }
            this.viewFile(next_id);
        },
        mutateImg(e) {
            // Функція для отримання кольору та іконки файлу

            return mutateImgFiles(e)
        },
    },
    mounted(){
        this.urlCaches = storeS.blob != undefined ? storeS.blob : [];
        // if(localStorage.getItem('urlFiles')) {
        //     this.urlCaches = this.urlCaches != null ? JSON.parse(localStorage.getItem('urlFiles')) : [] ;
        // }
    },
    computed: {
        user(){
            return storeS.userbase
        },
        perms(){
            return storeS.perms
        },
        uploadPercentage(){
            return storeS.uploadPercentage
        }
    }
}

</script>