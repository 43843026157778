<template>
    <modal :title="$t('desc')" @close="$emit('close')" >
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
                <b-card>
                    <b-row>
                        <b-col>
                            <div class="mb-3">
                                <h4 class="fs-15">{{ $t('version') }}</h4>
                                <input type="text" class="form-control" id="contactNumber" v-model="form.version" >
                            </div>
                        </b-col>
                        <b-col>
                            <div class="mb-3">
                                <h4 class="fs-15">{{ $t('Deadline') }}</h4>
                                <flat-pickr
                                    v-model="form.deadlineDate"
                                    :config="config"
                                    class="form-control"
                                ></flat-pickr>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card>
                    <h4 class="text-center"><b>{{$t('desc')}}</b></h4>
                    <QuillEditor v-model:content="form.comment" theme="snow" contentType="html" toolbar="full"/>
                </b-card>

        </template>
        <template v-slot:footer-bottom>
            <!-- <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</a> -->
            <b-button @click="add()" variant="success" class="waves-effect waves-light"><i class="align-bottom me-1"></i>{{ $t('Edit') }}</b-button>
        </template>
    </modal>

    <!-- Модальне вікно з деталями по пункту -->
    <editElement 
        v-if="showEditModal"
        @close="showEditModal = false"
        :objCard="this.obj"
    />

</template>

<script>
import modal from '@/components/modal/modal-lg'
import editElement from './editItem/index'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import { Updates } from '@/API.js'
import { storeS } from '@/store';

let apiServe = new Updates();

export default{
    props: ['obj'],
    components: { 
        modal,
        flatPickr,
        editElement
    },
    data(){
        return{
            form: {
                comment: ""
            },
            newOpportunity: {
                text: ''
            },
            newImprovement: {
                text: ''
            },
            newCorrected: {
                text: ''
            },
            descForm: {
                new_opportunities: [],
                improved: [],
                corrected: []
            },
            showEditModal: false,
            editElement: null,
            editIndex: null,
            item: {
                desc: '',
                checked: 0
            },
            config: {
                mode: "single",
                minDate: "today",
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: ""
            },
        }
    },
    created(){
        this.form = this.obj

        this.descForm.new_opportunities = this.obj.opportunities

        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
    },
    methods: {
        add(){
            var editForm = {
                comment: this.form.comment,
                deadlineDate: this.form.deadlineDate,
                version: this.form.version
            }

            apiServe.editUpdate(this.form.deadlineId, editForm).then(res => {
                if(res){
                    this.$toast.success(this.$t('changeswereSuccessful'));
                    this.$emit('close')
                    this.eventBus.emit('saveUpdate', true)
                }
            })
        },
       
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
}
</script>