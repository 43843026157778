<template>
    <!-- Модальне вікно з додаванням пунктів -->

    <modal :title="this.title" @close="$emit('close', this.array, this.type)">

        <template v-slot:body>

            <b-card>

                <div class="flex-grow-1 mb-2 mt-2">
                    <label class="fs-16">{{ $t('SpecifytheItemUpdate') }}</label>
                    <div class="input-group">
                        <input 
                            type="text"
                            v-model="form.text" 
                            class="form-control"
                            @keydown.enter="addElement"
                        >
                        <button 
                            @click="addElement()" 
                            class="btn btn-success" 
                            type="button"
                        >
                            <i class="ri-add-line"></i>
                        </button>
                    </div>
                </div>

            </b-card>

            <b-card>
                <label class="fs-16 mb-4">{{ $t('AddedItems') }}</label>

                <template v-if="array.length > 0">
                    
                    <ul class="list-unstyled vstack gap-3 mb-0">

                        <li v-for="(item, index) in this.array" :key="index">

                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <div class="avatar-xs flex-shrink-0 me-1">
                                        <div class="avatar-title rounded bg-soft-success text-success">#{{index+1}}</div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-2">
                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="item.text" disabled>
                                        
                                        <!-- Редагувати пункт -->
                                        <button 
                                            @click="getElement(item)" 
                                            class="btn btn-info" 
                                            type="button"
                                        >
                                            <i class="ri-pencil-line"></i>
                                        </button>

                                        <!-- Видалити пункт -->
                                        <button 
                                            @click="showRemoveItem.item = item; showRemoveItem.show = true;"
                                            class="btn btn-danger" 
                                            type="button"
                                        >
                                            <i class="ri-delete-bin-2-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </li>

                    </ul>

                </template>

                <template v-else >
                    <div class="mt-2 text-center" >
                        <h4 class="mb-3">{{ $t('notAddedItemstoUpdateYet') }} 🥺</h4>
                        <p class="text-muted mb-4">{{ $t('SpecifyPressPlusorEnter') }}<b>'Enter'</b></p>
                    </div>
                </template>

            </b-card>

        </template>

    </modal>

    <!-- Модальне вікно з деталями по пункту -->
    <editElement 
        v-if="showEditModal.show"
        @close="showEditModal.show = false"
        :objCard="this.objCard"
        :objElement="this.showEditModal.dataElement"
        :type="this.type"
    />

    <!-- Модальне вікно з підтвердженням видалення пункту -->
    <removeBox 
        v-if="showRemoveItem.show"
        @close="showRemoveItem.show = false"
        @deleteItem="deleteElement();showRemoveItem.show = false"
    />

</template>

<script>
import modal from '@/components/modal/modal-lg'
import editElement from './index.vue'
import removeBox from '../action/removeItem'  // модальне вікно підтвердження видалення детальної інформації по пункту
import { Updates } from '@/API.js'
import { storeS } from '@/store';
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Updates();

export default {
    props: ['objCard', 'type'],
    components: {
        modal,
        editElement,
        removeBox
    },
    data() {
        return {
            form: {
                text: ''
            },
            array: [],
            title: '',
            showEditModal: {
                dataElement: '',
                show: false
            },
            showRemoveItem: { // налаштування на видалення пункту
                show: false,
                item: '',
                type: ''
            },
        }
    },
    created(){
        console.log(this);
        this.getdata()
    },
    methods: {
        getdata(){
            // отримання даних

            switch (this.type) {
                case 'opportunities':
                    this.title = 'Нові можливості';
                    this.array = this.objCard.opportunities;
                    break;
                case 'improvements':
                    this.title = 'Покращення';
                    this.array = this.objCard.improvements;
                    break;
                case 'fixed':
                    this.title = 'Виправлення';
                    this.array = this.objCard.fixed;
                    break;

                default: 
                    this.title = 'Не відомо'
                    break
            }
        },
        addElement(){
            // Додати елемент

            switch (this.type) {

                case 'opportunities':
                    
                    apiServe.addOpportunity(this.form, this.objCard.deadlineId).then(result => {

                        if (result.status == 'done') {
                            this.array.push({
                                text: this.form.text,
                                opportunityId: result.data
                            })
                            this.form.text = "";
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка додавання пункту нових можливостей'; 
                            var statusRequest = result.status;
                            var api = 'addOpportunity';
                            var fileRequest = 'src/views/changelog/view/editItem/editItems.vue';
                            var params = {
                                form: this.form,
                                deadlineId: this.objCard.deadlineId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'improvements':
                    
                    apiServe.addImprovement(this.form, this.objCard.deadlineId).then(result => {

                        if (result.status == 'done') {
                            this.array.push({
                                text: this.form.text,
                                improvementId: result.data
                            })
                            this.form.text = "";
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка додавання пункту секції покращення'; 
                            var statusRequest = result.status;
                            var api = 'addImprovement';
                            var fileRequest = 'src/views/changelog/view/editItem/editItems.vue';
                            var params = {
                                form: this.form,
                                deadlineId: this.objCard.deadlineId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'fixed':
                    
                    apiServe.addFixed(this.form, this.objCard.deadlineId).then(result => {

                        if (result.status == 'done') {
                            this.array.push({
                                text: this.form.text,
                                fixedId: result.data
                            })
                            this.form.text = "";
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка додавання пункту секції виправлення'; 
                            var statusRequest = result.status;
                            var api = 'addFixed';
                            var fileRequest = 'src/views/changelog/view/editItem/editItems.vue';
                            var params = {
                                form: this.form,
                                deadlineId: this.objCard.deadlineId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;

                default: 
                    this.title = this.$t('NotKnown')
                    break

            }

        },
        deleteElement() {
            // Видалити елемент
            console.log(this.showRemoveItem)

            switch (this.type) {

                case 'opportunities': 

                    apiServe.deleteOpportunity(this.showRemoveItem.item.opportunityId).then(result => {

                        if(result.status == 'done') {
                            this.array = result.data;
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення пункту секції нові можливості'; 
                            var statusRequest = result.status;
                            var api = 'deleteOpportunity';
                            var fileRequest = 'src/views/changelog/view/editItem/editItems.vue';
                            var params = {
                                id: this.showRemoveItem.item.opportunityId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'improvements': 

                    apiServe.deleteImprovement(this.showRemoveItem.item.improvementId).then(result => {

                        if(result.status == 'done') {
                            this.array = result.data;
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення пункту секції покращення'; 
                            var statusRequest = result.status;
                            var api = 'deleteImprovement';
                            var fileRequest = 'src/views/changelog/view/editItem/editItems.vue';
                            var params = {
                                id: this.showRemoveItem.item.improvementId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'fixed': 

                    apiServe.deleteFixed(this.showRemoveItem.item.fixedId).then(result => {

                        if(result.status == 'done') {
                            this.array = result.data;
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення пункту секції виправлення'; 
                            var statusRequest = result.status;
                            var api = 'deleteFixed';
                            var fileRequest = 'src/views/changelog/view/editItem/editItems.vue';
                            var params = {
                                id: this.showRemoveItem.item.fixedId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;

                default: 
                    console.log('log');
                break
            }

        },
        getElement(item) {
            // Отримати або відредагувати пункт 

            switch (this.type) {

                case 'opportunities': 

                    apiServe.getOpportunity(item.opportunityId).then(result => {

                        if(result.status == 'done') {
                            this.showEditModal.dataElement = result.data
                            this.showEditModal.show = true
                            
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка отримання пункту секції нові можливості'; 
                            var statusRequest = result.status;
                            var api = 'getOpportunity';
                            var fileRequest = 'src/views/changelog/view/editItem/editItems.vue';
                            var params = {
                                id: item.opportunityId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'improvements': 

                    apiServe.getImprovement(item.improvementId).then(result => {

                        if(result.status == 'done') {
                            this.showEditModal.dataElement = result.data
                            this.showEditModal.show = true
                            
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка отримання пункту секції покращення'; 
                            var statusRequest = result.status;
                            var api = 'getImprovement';
                            var fileRequest = 'src/views/changelog/view/editItem/editItems.vue';
                            var params = {
                                id: item.improvementId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'fixed': 

                    apiServe.getFixed(item.fixedId).then(result => {

                        if(result.status == 'done') {
                            this.showEditModal.dataElement = result.data
                            this.showEditModal.show = true
                            
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка отримання пункту секції виправлення'; 
                            var statusRequest = result.status;
                            var api = 'getFixed';
                            var fileRequest = 'src/views/changelog/view/editItem/editItems.vue';
                            var params = {
                                id: item.fixedId
                            };
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;

                default: 
                    console.log('log');
                break

            }
        }
    }
}
</script>